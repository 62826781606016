<template>
	<main class="page-container" id="selfdxStart">
		<div class="page-header-full">
			<div class="container">
				<h2 class="page-header-full__title">자가진단 서비스</h2>
				<p class="page-header-full__desc">
					자가진단을 통해 회원님에게 필요한 지원사업을 추천 받고, <br />
					다양한 맞춤형 콘텐츠를 통해 회원님의 역량을 키워보세요
				</p>
			</div>
		</div>
		<div class="page-body">
			<video autoplay muted loop class="selfdx-start-video">
				<source src="~@/assets/img/selfdx/video-start.webm" type="video/webm" />
				<source src="~@/assets/img/selfdx/video-start.mp4" type="video/mp4" />
			</video>

			<div class="container">
				<div class="selfdx-start-box">
					<section class="selfdx-start-section">
						<h3 class="selfdx-start-section__title"><i class="icon-selfdx-start1"></i> 자가진단 서비스란?</h3>
						<p class="selfdx-start-section__desc">
							<strong>자가진단 서비스</strong>는 간단한 설문 정보를 바탕으로, 회원님 역량을 4가지 카테고리로 분류하여 분석하고 <br /><strong>AI가 적합한 지원사업과 콘텐츠를 맞춤형으로 추천</strong>하는 <strong>서비스</strong>입니다.<br />
							<strong class="mt1">자가진단 후, 자가진단 결과표를 통해 회원님에게 필요한 지원사업, 콘텐츠를 확인해 보세요!</strong>
						</p>
					</section>
					<section class="selfdx-start-section">
						<h3 class="selfdx-start-section__title"><i class="icon-selfdx-start2"></i>자가진단 결과표에서 어떤 것들을 확인할 수 있나요?</h3>
						<div class="selfdx-start-section__content">
							<ol class="selfdx-start-section__list">
								<li>
									<span class="selfdx-start-section__img">
										<img src="~@/assets/img/selfdx/img_diagnosis_1.png" alt="역량분석 결과이미지" />
									</span>
									<dl>
										<dt>역량분석</dt>
										<dd class="selfdx-start-section__desc">자가진단 결과 회원님의 <strong>현재의 역량을 분석</strong>하고,</dd>
										<dd class="selfdx-start-section__desc">역량발전을 위해 필요한 내용엔 무엇이 있는지 확인해보세요.</dd>
									</dl>
								</li>
								<li>
									<span class="selfdx-start-section__img">
										<img src="~@/assets/img/selfdx/img_diagnosis_2.png" alt="맞춤형 지원사업 결과이미지" />
									</span>
									<dl>
										<dt>맞춤형 지원사업</dt>
										<dd class="selfdx-start-section__desc">진단결과를 바탕으로 <strong>AI가 추천하는 지원사업을 확인</strong>하고,</dd>
										<dd class="selfdx-start-section__desc"><strong>지원사업 정보와 사업별 성공사례를 확인</strong>해보세요.</dd>
									</dl>
								</li>
								<li>
									<span class="selfdx-start-section__img">
										<img src="~@/assets/img/selfdx/img_diagnosis_3.png" alt="성공사례 결과이미지" />
									</span>
									<dl>
										<dt>맞춤형 콘텐츠</dt>
										<dd class="selfdx-start-section__desc">역량강화를 위한 <strong>다양한 맞춤형 콘텐츠를 확인</strong>하고</dd>
										<dd class="selfdx-start-section__desc">회원님에게 도움이 되는 <strong>판로정보</strong>를 살펴보세요.</dd>
									</dl>
								</li>
							</ol>
						</div>
					</section>
				</div>
				<div class="buttons">
					<button type="button" class="button-default is-large is-secondary is-rounded" @click="selfDiagnStart">자가진단 시작하기</button>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_SESSION } from '../../../store/_act_consts';
import { MUT_CLOSE_ALERT, MUT_SET_RETURN_ROUTE, MUT_SHOW_ALERT } from '../../../store/_mut_consts';
import { getItem, lengthCheck } from '../../../assets/js/utils';
export default {
	name: 'SelfDiagnosisStart',
	components: {},
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),
	},

	data: () => ({}),
	watch: {},
	created() {},
	beforeRouteLeave(to, from, next) {
		this.$store.commit(`common/${MUT_CLOSE_ALERT}`);
		next();
	},
	methods: {
		async isLoginCheck() {
			const res = await this.$store.dispatch(`auth/${ACT_GET_SESSION}`);
			if (!lengthCheck(res)) {
				// 로그인 체크
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					subText: '*자가진단 서비스는&nbsp;<strong>통합회원 대상 서비스</strong>입니다.',
					html: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
			} else {
				// 로그인 된 상태여도 통합회원 체크
				const authItem = getItem(res);
				if (Number(authItem.ssoSeq) === 0 || Number(authItem.userPattnCd) !== 400) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: '통합회원만 사용이 가능합니다.',
						html: true,
						yesfunc: () => {
							this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
							this.$router.push({ path: '/mypage2/home' });
						},
					});
				} else {
					this.$router.push({ name: 'SelfDiagnosisInput' });
				}
			}
		},
		selfDiagnStart() {
			this.isLoginCheck();
		},
	},
};
</script>
